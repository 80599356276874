// Polyfills
import 'focus-visible'
import 'inert-polyfill'
import cssVars from 'css-vars-ponyfill'
import smoothscroll from 'smoothscroll-polyfill'

//
import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from 'components/containers/App'
import * as serviceWorker from './serviceWorker'

// Init ponyfills
smoothscroll.polyfill()
cssVars({
	watch: true,
})

//
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
