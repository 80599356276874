import React, { useState } from 'react'
import { For } from 'react-loops'
import wrangle from './datawrangler'
import { fuzzyMatch } from 'rivvles-utils'
import { compiler as markdown } from 'markdown-to-jsx'
import s from './FactoidsExplorer.module.scss'

// Components
import FormItem from 'components/generic/form/FormItem'

// Assets
import factoidsSource from 'media/factoids.json'

//
const factoids = wrangle(factoidsSource)

//
export default function FactoidsExplorer() {
	const [filters, setFilters] = useState({ query: '', showAliases: true, onlyAliases: false })

	const onFiltersChange = ({ target }) => {
		const value = ['checkbox', 'radio'].includes(target.type) ? target.checked : target.value
		setFilters({ ...filters, [target.name]: value })
	}

	// Apply filters
	const factoidsFiltered = factoids
		.filter(factoid => !filters.query || fuzzyMatch(filters.query, factoid.key) || fuzzyMatch(filters.query, factoid.value))
		.filter(factoid => !filters.onlyAliases || factoid.isAlias)
		.filter(factoid => filters.showAliases || filters.onlyAliases || !factoid.isAlias)

	return (
		<div className={s.root}>
			{/* Filters */}
			<div className={s.filters}>
				<FormItem type="text" name="query" label="Find in name or content" value={filters.query} onChange={onFiltersChange} />
				<FormItem type="checkbox" name="showAliases" label="Show aliases" checked={filters.showAliases} onChange={onFiltersChange} />
				<FormItem type="checkbox" name="onlyAliases" label="Only aliases" checked={filters.onlyAliases} onChange={onFiltersChange} />
			</div>
			{/* Listing */}
			<div className={s.listing}>
				<p className={s.result_count}>
					<output>{factoidsFiltered.length} results</output>
				</p>
				<dl>
					<For
						of={factoidsFiltered}
						as={({ key, value, isAlias }) => {
							const keyAttr = key.replace(/[ :]/, '_')
							const valueAttr = value.replace('alias:', '').replace(/[ :]/, '_')
							const content = markdown(value)

							return (
								<div id={`factoid-${keyAttr}`}>
									<dt>{key}</dt>
									<dd>
										{isAlias && <a href={`#factoid-${valueAttr}`}>{content}</a>}
										{!isAlias && content}
									</dd>
								</div>
							)
						}}
					/>
				</dl>
			</div>
		</div>
	)
}
