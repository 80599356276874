import React from 'react'
import { Helmet } from 'react-helmet'
import { useBrowserTitle } from 'rivvles-utils'

// Components
import LibraryExplorer from 'components/site/LibraryExplorer'

//
export default function Home() {
	return (
		<div data-restrain="xl" data-space="xxxl">
			<Helmet>
				<title>{useBrowserTitle('Library')}</title>
			</Helmet>
			<section>
				<h1>Library documentation</h1>
				<p>
					This page attempts to explain how the library works and how to use and write plugins. Comments, suggestions, etc. welcome on <a href="https://github.com/colin-aarts/rivvles">rivvles' GitHub repo</a> or <a href="mailto:hello@rivvles.com">hello@rivvles.com</a>.
				</p>
				<div data-space="xxxl">
					<LibraryExplorer />
				</div>
			</section>
		</div>
	)
}
