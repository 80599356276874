import React from 'react'
import { paragraphify } from 'rivvles-utils'
import parseHTML from 'html-react-parser'
import s from './Error.module.scss'

// Components
import Button from 'components/generic/Button'
import Icon from 'components/generic/Icon'
import { ReactComponent as Wheel1 } from 'media/wheel-1.svg'
import { ReactComponent as Wheel2 } from 'media/wheel-2.svg'
import { ReactComponent as Wheel3 } from 'media/wheel-3.svg'

//
export default function ErrorScreen({ title, body }) {
	return (
		<article className={s.root}>
			<div className={s.main}>
				<h1>{title}</h1>
				{parseHTML(paragraphify(body))}
				<p>
					<Button element="Link" href="/" kind="plain" data-animate="icon-left">
						<Icon name="arrow-back" />
						<span>Back home</span>
					</Button>
				</p>
			</div>
			<div>
				<div className={s.wheels} role="presentation">
					<Wheel1 className={`${s.wheel} ${s.wheel1}`} />
					<Wheel2 className={`${s.wheel} ${s.wheel2}`} />
					<Wheel3 className={`${s.wheel} ${s.wheel3}`} />
				</div>
			</div>
		</article>
	)
}
