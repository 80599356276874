import React from 'react'
import s from './Icon.module.scss'

// Icons
import { ReactComponent as ArrowBack } from 'icons/arrow-back.svg'
import { ReactComponent as ArrowDropDown } from 'icons/arrow-drop-down.svg'
import { ReactComponent as ArrowForward } from 'icons/arrow-forward.svg'
import { ReactComponent as CheckIcon } from 'icons/check.svg'
import { ReactComponent as ChevronLeftIcon } from 'icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from 'icons/chevron-right.svg'
import { ReactComponent as CloseIcon } from 'icons/close.svg'
import { ReactComponent as CreateIcon } from 'icons/create.svg'
import { ReactComponent as ErrorIcon } from 'icons/error.svg'
import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as MenuIcon } from 'icons/menu.svg'
import { ReactComponent as MinusCircleIcon } from 'icons/minus-circle.svg'
import { ReactComponent as PaymentIcon } from 'icons/payment.svg'
import { ReactComponent as PlusCircleIcon } from 'icons/plus-circle.svg'
import { ReactComponent as SyncIcon } from 'icons/sync.svg'
import { ReactComponent as TimeIcon } from 'icons/time.svg'
import { ReactComponent as TranslateIcon } from 'icons/translate.svg'
import { ReactComponent as WalletIcon } from 'icons/wallet.svg'

// Defs
const COMPONENT_MAP = {
	'arrow-back': ArrowBack,
	'arrow-drop-down': ArrowDropDown,
	'arrow-forward': ArrowForward,
	'check': CheckIcon,
	'chevron-left': ChevronLeftIcon,
	'chevron-right': ChevronRightIcon,
	'close': CloseIcon,
	'create': CreateIcon,
	'error': ErrorIcon,
	'info': InfoIcon,
	'menu': MenuIcon,
	'minus-circle': MinusCircleIcon,
	'payment': PaymentIcon,
	'plus-circle': PlusCircleIcon,
	'sync': SyncIcon,
	'time': TimeIcon,
	'translate': TranslateIcon,
	'wallet': WalletIcon,
}

/**
 * This is the basic icon component. We primarily use icons from Google's Material icon set.
 *
 * ### Implementation
 *
 * Place SVGs in the `src/icons` directory. Pass icon name to `Icon` component via `name` prop. All other props are passed-through as-is.
 */
export default function Icon({ name, className, ...props }) {
	const IconSVG = COMPONENT_MAP[name]

	return (
		<span className={`${s.root} ${className || ''}`} data-component="Icon" role="presentation" {...props}>
			<IconSVG />
		</span>
	)
}
