import React from 'react'
import Markdown from 'markdown-to-jsx'
import raw from 'raw.macro'
import s from './LibraryExplorer.module.scss'

// Config
import markdownConfig from 'utils/markdownConfig'

//
export default function LibraryExplorer() {
	return (
		<div className={s.root}>
			<div className={s.toc}>
				<p className={s.toc_title}>Table of contents</p>
				<ul>
					<li>
						<a href="#using-rivvles">Using rivvles</a>
						<ul>
							<li>
								<a href="#from-the-command-line">From the command line</a>
							</li>
							<li>
								<a href="#as-a-library">As a library</a>
							</li>
							<li>
								<a href="#env-file">
									<code>.env</code> file
								</a>
							</li>
							<li>
								<a href="#configuration">Configuration</a>
							</li>
						</ul>
					</li>
					<li>
						<a href="#command-anatomy">Command anatomy</a>
					</li>
					<li>
						<a href="#plugin-architecture">Plugin architecture</a>
						<ul>
							<li>
								<a href="#registercommand">
									<code>registerCommand</code>
								</a>
								<ul>
									<li>
										<a href="#inputdata">
											<code>inputData</code>
										</a>
									</li>
									<li>
										<a href="#eventdata">
											<code>eventData</code>
										</a>
									</li>
									<li>
										<a href="#client">
											<code>client</code>
										</a>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					<li>
						Plugins
						<ul>
							<li>
								<a href="#plugin-rivvles-admin">rivvles-admin</a>
							</li>
							<li>
								<a href="#plugin-rivvles-bitly">rivvles-bitly</a>
							</li>
							<li>
								<a href="#plugin-rivvles-admin">Foo</a>
							</li>
							<li>
								<a href="#plugin-rivvles-admin">Bar</a>
							</li>
							<li>
								<a href="#plugin-rivvles-admin">Baz</a>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div className={s.content}>
				<Markdown options={markdownConfig}>{raw('../../../content/library.md')}</Markdown>
			</div>
		</div>
	)
}
