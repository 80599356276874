import React from 'react'
import hljs from 'highlight.js'
import 'highlight.js/styles/github.css'

// Highlight.js languages
import xml from 'highlight.js/lib/languages/xml'
import json from 'highlight.js/lib/languages/json'
import javascript from 'highlight.js/lib/languages/javascript'

// Init highlight.js languages
hljs.registerLanguage('xml', xml)
hljs.registerLanguage('json', json)
hljs.registerLanguage('javascript', javascript)

// <a>
export const A = ({ children, ...props }) => {
	return (
		<a target="_blank" {...props}>
			{children}
		</a>
	)
}

// <code>
export const Code = ({ className, children, ...props }) => {
	const lang = className?.split('-')[1] // className should/will always be in the form `lang-js`
	const highlighted = lang ? hljs.highlight(lang, children) : children
	const output = highlighted?.value || children.replace(/</g, '&lt;')
	return <code {...props} dangerouslySetInnerHTML={{ __html: output }} />
}
