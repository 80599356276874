/**
 * Fuzzy matcher
 * Lowest effort fuzzy matching. Zero fault tolerance, no transmutes, no scoring, etc.
 * @param {String} query - Query string
 * @param {String} target - String to search in
 * @return {Boolean} - Whether or not a match was found
 */
export default (query, target) => {
	const pattern = query.split('').join('.?')
	const re = new RegExp(`.*${pattern}.*`, 'i')
	return re.test(target)
}
