import React from 'react'
import { Helmet } from 'react-helmet'
import { useBrowserTitle } from 'rivvles-utils'

// Components
// import FactoidsExplorer from 'components/site/FactoidsExplorer'

//
export default function Home() {
	return (
		<div data-restrain="max">
			<Helmet>
				<html data-page="Home" />
				<title>{useBrowserTitle('Home')}</title>
			</Helmet>
		</div>
	)
}
