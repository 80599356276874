import React from 'react'

// Components
import Error from 'components/site/Error'

//
export default function ErrorPage() {
	return (
		<div data-restrain="max">
			<Error title="404" body="Page not found" backToHomeLabel="Back home" />
		</div>
	)
}
