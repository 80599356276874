// import React from 'react'

// Components
import Home from 'components/pages/Home'
import Factoids from 'components/pages/Factoids'
import Library from 'components/pages/Library'
import NotFound from 'components/pages/NotFound'

/**
 * @todo: document config options
 */
export default [
	{
		path: '/',
		title: 'Home',
		exact: true,
		// Component: React.lazy(() => import('components/pages/Home')),
		Component: Home,
	},
	{
		path: '/factoids',
		title: 'Factoids',
		exact: true,
		// Component: React.lazy(() => import('components/pages/Factoids')),
		Component: Factoids,
	},
	{
		path: '/library',
		title: 'Library',
		exact: true,
		// Component: React.lazy(() => import('components/pages/Library')),
		Component: Library,
	},
	{
		title: 'Not found',
		// Component: React.lazy(() => import('components/pages/NotFound')),
		Component: NotFound,
	},
]
