import React from 'react'
import { uid } from 'rivvles-utils'
import s from './FormItem.module.scss'

// Components
import Icon from 'components/generic/Icon'

//
export default function FormItem({ type = 'text', value, defaultValue, checked, defaultChecked, min, max, step, label, description, error, required, name, disabled, id, className, labelClass, onChange, children, ...props }) {
	id || (id = `${name}-${uid()}`)

	const isCheckable = ['radio', 'checkbox'].includes(type)

	const $label = (
		<label htmlFor={id} className={`${s.label} ${labelClass}`}>
			{label}
		</label>
	)

	return (
		<div className={`${s.root} ${className || ''}`} data-is-checkable={isCheckable} {...props}>
			<div className={s.field_and_label}>
				{!isCheckable && $label}
				<span className={s.control_wrapper}>
					<input
						// prettier-ignore
						name={name}
						id={id}
						type={type}
						value={value}
						defaultValue={defaultValue}
						checked={checked}
						defaultChecked={defaultChecked}
						min={min}
						max={max}
						step={step}
						onChange={onChange}
						required={required}
						disabled={disabled}
					/>
					{/* {type === 'number' && value != null && (
						<span role="presentation" className={s.formatted_value}>
							{(+value).toLocaleString()}
						</span>
					)} */}
				</span>
				{isCheckable && $label}
			</div>
			{error && (
				<p className={s.error}>
					{error}
					<Icon className={s.error_icon} name="error" />
				</p>
			)}
			{description && <p className={s.description}>{description}</p>}
			{children && children}
		</div>
	)
}
