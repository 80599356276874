import React, { Suspense } from 'react'
import { createBrowserHistory } from 'history'
import { wrapHistory } from 'oaf-react-router'
import AppCtx from 'contexts/App'

// Components
import App from 'components/site/App'
import SplashScreen from 'components/site/SplashScreen'
import ErrorBoundary from 'components/containers/ErrorBoundary'

// Config
import routes from 'config/routes'

// Init accessible routing
const history = createBrowserHistory()
wrapHistory(history, {
	smoothScroll: true,
	// Don't let oaf handle fragment navigation
	shouldHandleAction: (prev, next, action) => !next.hash,
})

//
export default function AppContainer() {
	return (
		<ErrorBoundary>
			<Suspense fallback={<SplashScreenContainer />}>
				<AppContainerInner />
			</Suspense>
		</ErrorBoundary>
	)
}

const AppContainerInner = () => {
	const appContext = {}

	return (
		<AppCtx.Provider value={appContext}>
			<App history={history} routes={routes} />
		</AppCtx.Provider>
	)
}

const SplashScreenContainer = () => {
	return (
		<div data-restrain="max">
			<SplashScreen />
		</div>
	)
}
