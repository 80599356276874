import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import s from './App.module.scss'

// Components
import SiteHeader from 'components/site/SiteHeader'
import ErrorBoundary from 'components/containers/ErrorBoundary'

//
export default function App({ history, routes }) {
	return (
		<div className={s.root}>
			<a href="#main-content" className="visually-hidden">
				Skip to main content
			</a>
			<main id="main-content">
				<Router history={history}>
					<ErrorBoundary history={history}>
						<div className="sticky">
							<SiteHeader />
						</div>
						<ErrorBoundary history={history}>
							<Switch>
								{routes.map(({ path, exact, Component }) => {
									return <Route key={path || ''} path={path} exact={exact} component={Component} />
								})}
							</Switch>
						</ErrorBoundary>
					</ErrorBoundary>
				</Router>
			</main>
		</div>
	)
}
