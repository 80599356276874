import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useBrowserTitle } from 'rivvles-utils'

// Components
import FactoidsExplorer from 'components/site/FactoidsExplorer'

//
export default function Home() {
	return (
		<div data-restrain="xl" data-space="xxxl">
			<Helmet>
				<title>{useBrowserTitle('Factoids')}</title>
			</Helmet>
			<section>
				<h1>Factoids at Freenode</h1>
				<p>
					rivvles can currently be found in #html and #rivvles on Freenode. Below an overview of factoids it knows. Additionally, rivvles is also loaded with a bunch of plugins providing special commands; see the <Link to="/library">library docs</Link> for details.
				</p>
				<div data-space="xl">
					<FactoidsExplorer />
				</div>
			</section>
		</div>
	)
}
